* {
  box-sizing: border-box !important;
}

::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #e4e4e4; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #999999; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #e4e4e4; /* creates padding around scroll thumb */
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
}

h4 {
  font-size: 22px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav .nav-wrapper.white {
  background: #282828 !important;
}

.menu-wrapper .brand-logo {
  width: 220px;

  max-width: 100%;
}

.menu-wrapper .brand-logo img {
  max-width: 100%;
}

.input-field > label {
  font-size: 16px;

  line-height: 24px;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
  border: 1px solid rgba(0, 0, 0, 0.44);

  border-radius: 20px;

  height: 54px;

  padding: 0 15px;

  font-size: 14px;
}

.input-field.col label {
  left: 2rem;

  line-height: 28px;
}

.input-field > label:not(.label-icon).active {
  transform: translateY(-22px) scale(0.8);
}

.input-field
  > input[type]:-webkit-autofill:not(.browser-default):not([type="search"])
  + label,
.input-field > input[type="date"]:not(.browser-default) + label,
.input-field > input[type="time"]:not(.browser-default) + label {
  transform: translateY(-22px) scale(0.8);
}

input.invalid:not([type]),
input.invalid:not([type]):focus,
input.invalid[type="text"]:not(.browser-default),
input.invalid[type="text"]:not(.browser-default):focus,
input.invalid[type="password"]:not(.browser-default),
input.invalid[type="password"]:not(.browser-default):focus,
input.invalid[type="email"]:not(.browser-default),
input.invalid[type="email"]:not(.browser-default):focus,
input.invalid[type="url"]:not(.browser-default),
input.invalid[type="url"]:not(.browser-default):focus,
input.invalid[type="time"]:not(.browser-default),
input.invalid[type="time"]:not(.browser-default):focus,
input.invalid[type="date"]:not(.browser-default),
input.invalid[type="date"]:not(.browser-default):focus,
input.invalid[type="datetime"]:not(.browser-default),
input.invalid[type="datetime"]:not(.browser-default):focus,
input.invalid[type="datetime-local"]:not(.browser-default),
input.invalid[type="datetime-local"]:not(.browser-default):focus,
input.invalid[type="tel"]:not(.browser-default),
input.invalid[type="tel"]:not(.browser-default):focus,
input.invalid[type="number"]:not(.browser-default),
input.invalid[type="number"]:not(.browser-default):focus,
input.invalid[type="search"]:not(.browser-default),
input.invalid[type="search"]:not(.browser-default):focus,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea.invalid:focus,
.select-wrapper.invalid > input.select-dropdown,
.select-wrapper.invalid > input.select-dropdown:focus {
  border-color: #f44336;

  box-shadow: 0 0 0;

  outline: none;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #0082b8;

  box-shadow: 0 1px 0 0#0082B8;

  outline: none;
}

input:not([type]):focus:not([readonly]) + label,
input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
  + label,
input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
input[type="number"]:not(.browser-default):focus:not([readonly]) + label,
input[type="search"]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #0082b8;

  outline: none;
}

a {
  color: #0082b8;
}

.menu-wrapper {
  font-size: 15px;

  padding: 15px 0 !important;

  padding-bottom: 0 !important;
}

.menu-wrapper a,
.menu-wrapper button {
  display: inline-block;

  padding: 10px;

  text-align: center;

  color: #ffffff;
}

.menu-wrapper a:hover,
.menu-wrapper button:hover {
  color: #f9f9f9;
}

input[type="checkbox"].filled-in + span:not(.lever):after {
  top: 3px !important;

  left: 15px !important;
}

input[type="checkbox"] + span:not(.lever) {
  padding-left: 45px !important;
}
input[type="checkbox"].filled-in:checked + span:not(.lever):before {
  top: 3px;
  left: 16px;
}

input[type="checkbox"].filled-in:checked + span:not(.lever):after {
  border: 2px solid #282828;

  background-color: #282828;
}

.subnav {
  position: fixed;
  top: 60px;
  width: 100%;
  background: #ffffff;
  color: #282828;
}

.subnav .menu-wrapper a,
.subnav .menu-wrapper button {
  color: #282828;
}

.subnav .menu-wrapper a img {
  width: 12px;

  margin-right: 5px;
}

.subnav .menu-wrapper {
  padding-top: 5px !important;

  margin-left: 40px !important;
}

.subnav .row {
  margin-bottom: 10px !important;
}

.upload-section {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  padding: 10px 20px 20px 20px;
  border-radius: 20px;
  width: 800px;
  height: 520px;
  text-align: center;
  position: fixed;
  top: 50%;
  margin-top: -250px;
  left: 50%;
  margin-left: -400px !important;
  z-index: 100;
}

.upload-section p.left {
  text-align: left;

  font-size: 12px;
}

.drop-zone {
  background: #f9f9f9;

  border: 1px dashed #e4e4e4;

  border-radius: 10px;

  text-align: center;

  height: 340px !important;
}

.upload-section .image-preview {
  margin-top: 10px;
}

.uploadPicturesWrapper {
  position: absolute;

  top: 0;

  width: 100% !important;

  margin: 0;
}

.uploadPictureContainer {
  width: 100% !important;

  margin: 0 !important;

  padding: 0 !important;

  border-radius: 50% !important;

  overflow: hidden;
}
.fileContainer {
  background: transparent !important;

  padding: 0 !important;

  margin: 0 !important;

  width: 168px !important;

  height: 168px !important;

  border-radius: 50% !important;

  left: -12px;

  position: relative;
}

.fileContainer .deleteImage {
  top: 20px !important;

  right: 20px !important;

  display: none;
}

.fileContainer .chooseFileButton {
  position: absolute;

  z-index: 10;
}
.card-image {
  height: 180px;

  overflow: hidden;

  background: #e4e4e4;
}

.card .card-image .card-title {
  color: #282828;
}

.active-user,
.active-user label,
.verified-phone label {
  color: green;
}

.inactive-user,
.inactive-user label,
.notverified-phone label {
  color: red;
}

.notify-user,
.notify-user label {
  color: orange;
}

.popup {
  position: fixed;
  top: 18%;
  left: 10%;
  width: 80%;
  height: 75%;
  z-index: 98;
  background: #ffffff;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #282828;

  overflow-y: scroll;

  overflow-x: hidden;
}

button.btn {
  border-radius: 12px;
}

.file-field.input-field .btn {
  width: 100px;

  height: 100px;

  background: #e4e4e4;
}

.file-field.input-field .btn span {
  background: transparent url("images/add-icon-rounded.png") no-repeat center
    center;
  cursor: pointer;
  display: inline-block;
  width: 37px;
  height: 36px;
  text-indent: -999px;
  margin-top: 28px;
}
thead {
  background: #f5f5f5;
}
tr:last-child {
  border-bottom: 0 transparent;
}

.btn,
.btn-large,
.btn-small,
.btn-flat {
  text-transform: none !important;
}
.bid-requests-icon {
  width: 16px;
  position: relative;
  top: 3px;
}

.container {
  display: flex;
  justify-content: center;
}
.cards {
  width: 100%;
  display: flex;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 18%);
  height: 150px;
  border: 1px solid #00000040;
  background-color: #ffffff;
}
.cards .leftBlock {
  width: 85%;
  padding: 1em;
  height: 100%;
  border-right: 1px solid #00000056;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cards .leftBlock ul {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  height: 50%;
  margin: -20px;
}
.cards .leftBlock ul li {
  display: inline-flex;
  padding: 0 5px;
  font-weight: 600;
}
.cards .leftBlock p {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background: #fff;
}
.cards h6 {
  font-weight: 600;
  margin: 0;
}
.cards .rightblock {
  width: 20%;
  padding: 0.5em;
  height: 100%;
}

.custombtn {
  width: 90px;
  height: 30px;
  background-color: #000000;
  border-radius: 45px;
  border: none;
  color: #ffffff;
}
.custombtn:hover {
  cursor: pointer;
}
.custombtn:focus {
  background: #000000;
}
.custombtn:disabled{
  color:#999999 !important;
  cursor: not-allowed;
}

.cards .rightblock ul {
  margin: 0;
}
.cards .rightblock li {
  display: flex;
  padding: 2px 0;
  justify-content: center;
}
.toolbar {
  width: 100%;
  border-radius: 10px;
  height: 50px;
  border: 1px solid #00000050;
}
.toolbar ul {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 100%;
  margin: 0px;
}
.toolbar ul li {
  display: inline-flex;
  padding: 0 5px;
}
.toolbar ul li span {
  cursor: pointer;
}
.nav-widgets {
  margin: 2em 0 2em 0;
  display: flex;
  width: 100%;
  border-radius: 10px;
  height: 40px;
}

.nav-widgets .btn-transparent {
  width: 85px;
  border-radius: 15px;
  border: 1px solid #000000;
}
.nav-widgets .btn-transparent:focus {
  border: 1px solid #000000;
}

.nav-widgets .searchbox {
  position: relative;
  margin-left: 10px;
  width: 82%;
}
.nav-widgets .searchbox input {
  border-radius: 15px;
  height: 40px;
  margin-right: 10px;
  padding-left: 30px;
  width: 240px;
  border: 1px solid #000000;
}
.nav-widgets .searchbox img {
  position: absolute;
  left: 0;
  top: 13px;
}

/* .nav-widgets .col .s2 .right-border{
  border:1px solid black !important;
  border-radius: 15px;
  height: 100% !important;
} */
.pagination {
  margin: 3rem 0;
  text-align: center;
}

.pagination button {
  color: black;
  padding: 12px 18px;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 30%);
  border-radius: 5px;
  margin-right: 5px;
  font-weight: 500;
  background: #ffffff;
  border: none;
  text-decoration: none;
}
.pagination button:hover {
  cursor: pointer;
}
.pagination .activepage {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.disabled {
  opacity: 0.6;
}
.bid_proposal {
  position: absolute;
  top: 52px;
  padding-left: 0 !important;
  z-index: 999;
  overflow: hidden;
  max-height: 90%;
  width: 37%;
  border-radius: 15px;
}
.bid_proposal .modal-content {
  padding: 24px 0px 12px 0px;
}
.bid_proposal .modal-title {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.5rem;
}
.bid_proposal .modal-header {
  position: relative;
}
.bid_proposal .modal-body {
  max-height: 480px;
  overflow-y: auto;
  padding: 0 20px;
}
.bid_proposal .close {
  background: transparent;
  border: none;
  font-size: 32px;
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: -21px;
}
.bid_proposal .close span:nth-child(2){
  display: none;
}
.bid_proposal input {
  height: 40px !important;
  border-radius: 10px !important;
}
.dropdown-sel {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.44);
  background: transparent;
}
.dropdown-sel option:hover {
  background: #000000;
}
.bid_proposal .prictag {
  position: absolute;
  left: 40px;
  height: 40px;
  padding-right: 10px;
  align-items: center;
  display: flex;
  border-right: 1px solid #00000056;
}
.bid_proposal .projID {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: block;
}
.bid_proposal .input-field {
  margin-bottom: 1.5rem;
  padding-right: 0.5rem;
}
.bid_proposal .title {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  padding-left: 15px;
  margin-bottom: 20px;
}
.bid_proposal .modal-footer {
  text-align: center;
}
.bid_proposal .modal-footer button {
  width: 200px;
  border-radius: 15px;
  height: 40px;
}
.bid_proposal .modal-footer button:first-child {
  background-color: transparent !important;
  margin-right: 10px;
  color: #000000;
  border: 1px solid #000000;
}
.bid_proposal .attachment {
  height: 70px;
  border-radius: 10px;
  width: 65px;
  border: 1px solid #00000056;
  background: #e8e8e896;
}
.bid_proposal .materialize-textarea {
  height: 70px;
  padding-top: 7px;
  border-radius: 10px;
}
.modal-backdrop {
  background-color: #666666;
  width: 100%;
  opacity: 0.5;
  z-index: 997;
  left: 0;
  top: 0;
  position: fixed;
  height: 100%;
}
.p-0 {
  padding: 0 !important;
}
.pl-0{
  padding-left: 0 !important;
}
.pr-0{
  padding-right: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.Mybid-proposal h5 {
  font-size: 20px;
  font-weight: 600;
}
.Mybid-proposal label {
  font-size: 14px;
  color: #000000;
  display: block;
  font-weight: 600;
}
.mb-1r {
  margin-bottom: 1rem;
}
.bid_proposal .input-field .react-datepicker-wrapper {
  width: 100%;
}
.bid_proposal .input-field .react-datepicker-wrapper input {
  margin: 0;
}
.bid_proposal .file-field input {
  height: 100% !important;
  width: 100%;
}
.custombtn a {
  color: #f5f5f5;
}
.collapse:not(.show) {
  display: none;
}
.accordion .card {
  background-color: rgb(255, 255, 255);
  position: relative;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid rgba(112, 112, 112, 0.27);
  margin-bottom: 30px;
  margin-top: 20px;
}
.accordion .card .accordion_toggle {
  position: absolute;
  left: 43%;
  width: 140px;
  cursor: pointer;
  border-radius: 30px;
  border: 1px solid #00000056;
  background: #ffffff;
  outline: none;
  height: 30px;
  bottom: -16px;
}
.accordion .custombtn {
  padding: 0px 25px;
  height: 25px;
  width: auto;
  letter-spacing: 0.5px;
  margin-right: 15px;
}
.comm-sec .profIcon {
  padding: 0;
  font-size: 36px;
  color: #acacac;
}
.comm-sec .comm-profile {
  padding-left: 7px;
  padding-top: 7px;
  margin: 0;
}
.comm-profile label {
  display: block;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}
.comm-profile span {
  font-size: 12px;
}
.no_data {
  text-align: center;
  vertical-align: middle;
  line-height: 90px;
}
.quoteform {
  background: #ffffff;
  margin-top: 20px !important;
  border: 1px solid #acacac;
  border-radius: 15px;
  padding: 30px 10px;
}
.quoteform input {
  height: 54px !important;
}
.quoteform textarea {
  height: 120px !important;
  border-radius : 20px;

  resize: none;
}
.quoteform select {
  height: 54px !important;
  border-radius: 20px;
}
.quoteform label {
  color: #000000;
}
.quoteform .validate,
.datepicker {
  height: 42px !important;
}
.quoteform .materialize-textarea {
  height: 54px;
  padding-top: 5px;
}
.quoteform .react-datepicker-wrapper {
  display: block;
}
.quoteform .line-items select, .quoteform .line-items input  {
  height : 42px!important;
  border-radius: 15px!important;
}
.mt-0 {
  margin-top: 0px !important;
}
.t-0 {
  top: 0px !important;
}
.multiselect_skills {
  padding: 10px;
}
.multiselect_skills .kn-multi_select__wrapper___30BEc {
  border: none;
}
.multiselect_skills
  .kn-multi_select__wrapper___30BEc
  .kn-column__column___3Iwzx {
  padding: 10px;
  border: 1px solid #acacac;
  border-radius: 15px;
  margin-right: 10px;
}
.multiselect_skills
  .kn-multi_select__wrapper___30BEc
  .kn-column__column___3Iwzx
  .resize-triggers {
  border: 1px solid #acacac;
  border-radius: 15px;
}
.multiselect_skills .kn-column__column___3Iwzx .kn-search__input___2tyxf {
  border: none;
}
.multiselect_skills
  .kn-multi_select__wrapper___30BEc
  .kn-column__column___3Iwzx
  .kn-list__list___22Wuc {
  height: 335px !important;
}
.add_skills {
  display: flex;
  justify-content: flex-end;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.loader_small {  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 50%;
}
.loading_spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #00000056; /* black */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.close_icon {
  padding: 0px;
  cursor: pointer;
  outline: none;
  position: relative;
  background-color: #ffffff;
  right: -18px;
  font-size: 20px;
  border: none;
  top: 11px;
}
.close_icon:focus {
  background-color: #ffffff;
}
.add_customer {
  display: flex;
  justify-content: flex-end;
}
.active_link, .menu-wrapper a:not(.brand-logo):hover, .menu-wrapper button:hover{
  background: white !important;
  color: black !important
}
.active_link_two, .subnav .menu-wrapper a:hover, .subnav .menu-wrapper button:hover{
  border-bottom: 2px solid #000000;
  padding-bottom: 4px;
}
tr.closed {

    background-color: #eac5c5;
}
tr.closed td {
      
      border-radius: 0px;
}
.txt_align_c{
  text-align: center;
}
.addSkillsMdl{
  top: 28% !important;
}
.listDopdown{
  position: relative;
  top: 21px;
  width: 150px;
}
.listDopdown .drop-head{
  display: block;
  height: 40px;
  border: 1px solid black;
}
.listDopdown .drop-body{
  position: absolute;
  padding: 10px;
  top: 40px;
  max-height: 320px;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  width: 450px;
  background: #ffffff;
  left: 0;
}
.listDopdown .drop-body span{
  display: block;
    width: 440px;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.listDopdown .drop-body button{
  float: right;
}
.listDopdown .drop-body .drop-list li{
  height: 70px;
  padding: 5px;
  border-bottom: 2px solid #f4f4f4;
}
.backBg{
  background-color: #ffffff  !important;
  color: #000000 !important;
}
@media screen and (min-width: 1280px) {
  .bid_filter{
    margin-right: -48px;
  }
}
@media screen and (min-width: 1366px) {

  .bid_filter{
    margin-right: -80px;
  }
}

div[role="columnheader"] div{
  font-weight: bold;
  font-size : 14px;
}
.view_profile_only{
  display: flex;
  justify-content: center;
  align-items: center;
}
.starIcon{
position: relative;
top: 5px;
display: inline-block;
font-size: 3rem;
color: #d3d3d3;
}
.star{
  position: absolute;
  overflow: hidden;
  display: inline-block;
}
.activeStar{
  color: orange;
}
.ratingHead{
  display: inline-block;
  font-size: 24px;
  margin-right: 10px;
}
.ratingCustName{
  margin-right: 10px;
  font-size: 18px;
}
.ratingblock{
  max-height: 440px;
  min-height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.customerRatings .starIcon{
  font-size: 2rem !important;
}
.card-label {
  font-size: 14px; 
  font-weight: bold; 
  color:#222222;
  padding-top: 20px;
  display: inline-block;
}