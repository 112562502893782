.root-comment-area {

	border-radius : 20px;
	resize: none;
	height: 120px;
	width: 100%;
	padding : 20px;
}

.reply-btn {
	border : 0 none;
	background: transparent;
	text-decoration: underline;
	cursor: pointer;
}
.reply-btn:after {
	display: inline-block;
	content:" ";
	width:12px;
	height : 12px;
	background : transparent url(../../images/reply-icon.png) no-repeat center bottom;
	background-size: 12px 12px;
	margin-left : 12px;
}
.replycomments {
	display: none;
}

.imgdiv img{
	width: 42px;
	height: 42px;
	border-radius: 50%;
}
div[id^=reply]{
	display: none;
}
.borderone {
	border-top:1px solid #e4e4e4;
	margin-top : 10px;
	padding-top : 10px!important;
}

.file-field.input-field.attachment {
	width: 42px; 
	height: 42px;
}

span.file-field.input-field img {
	width : 50%;
}