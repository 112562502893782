body {

    background : rgba(246,246,246,0.96);

}

.navbar-fixed.auth {

    width: 340px;

    height: 62px;
    
    margin: 0 auto;

    margin-top : 5%;

}

.navbar-fixed.auth nav {

    position : relative;

    height: 62px;

    line-height: 62px;

    border-radius: 20px 20px 0 0;

}

.navbar-fixed.auth nav .nav-wrapper.white {

    border-radius: 20px 20px 0 0;

}

.container.auth {

    max-width: 340px;

    background : #ffffff;

    padding : 6px;

    border: 1px solid rgba(112,112,112,0.27);

    border-radius: 0 0 20px 20px;

}


.container.auth .row .col.offset-s2 {

    margin-left: 0;
}

.container.auth .row .col.s8 {

    width : 100%;

    margin : 0!important;

}

.container.auth h4 {

    font-size: 36px;

    line-height: 49px;

    color : #282828;

    text-align : center;

    font-weight: 400;
}
.header-text {

    font-size : 42px;
    
    font-weight: 300;

    margin-top : 20px!important;

    margin-top : 10px!important;

}

ul.highlights {

    padding : 0;

    margin: 30px 0 0 0;

}

ul.highlights li{

    font-size : 18px;

    line-height: 42px;

    list-style: square;
}
.container.auth input{

    height: 42px!important;

}