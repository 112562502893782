.cards .rightBlock{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rightBlock li a{
    width: 100%;
    padding: 6px 15px;
}
.inputselect select{
    border-radius: 16px;
      height: 3.5rem;
      border: 1px solid #00000070;
      margin: 0px 0px 10px 0px;
  }
  .error-text{
    position: absolute;
    top: 50px;
    left: 20px;
  }
  #country{
      background: none;
  }
  .valign-wrapper{
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 10px;
    border: 1px solid rgba(112, 112, 112, 0.27);
    margin-bottom: 30px;
    margin-top: 20px;
  }
  tr,tr:last-child {
    border-bottom: 2px solid rgba(0,0,0,0.12) !important;
}
.btn-transparent{
    border:none;
    background: transparent;
}
.pass-match{
    position: absolute;
    bottom: 20px;
    left: 20px;
}

/* modal  */
.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px #f1f1f1;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 10px;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
}