body {
    background: rgba(246,246,246,0.96);
}

nav .nav-wrapper.white {

	text-align: left;

}

nav .nav-wrapper.white .brand-logo {

	padding : 10px 20px;
}

nav .nav-wrapper.white .brand-logo img {

	width: 240px;

	max-width : 100%;

}

.container {

	background: rgba(246,246,246,0.96);

	display: block;

	width: 100%;

	max-width: 960px;

	margin : 0 auto;

}

.container.grid-container {

	width :90%;

	max-width: 1300px;

	margin-top : 0;

}

.row .col.module {

	border-radius : 20px;

	border : 1px solid rgba(112,112,112,0.27);

	background : #ffffff;

	width: 100%;

	display: block;

	padding : 20px;

	margin : 10px auto;

	position : relative;

	min-width: 100%;

}



.profile-container {

	position: relative;

	margin-bottom : 80px!important;
}

.profile-container.no-margin {

	margin-bottom : 0px!important;
}

.profile-container .profile-pic-container{ 

	width : 168px!important;

	height: 168px!important;

	border-radius : 50%;

	border: 1px solid rgba(46,46,46,0.51);

	background : #ffffff;

	position: absolute;

	top : -150px;

	left : 20px!important;

	background : #ffffff url(../../images/file-upload-avatar.png) no-repeat center bottom;

	overflow : hidden;

}

.profile-container .profile-pic-container input[type="file"]{

	position: absolute;

	opacity : 0.01;

	z-index: 101;

	cursor: pointer;

}

.profile-container .profile-pic-container button.btn{

	position : absolute;

	z-index : 99;

	bottom : 30px;

	left : 22px;

	font-size : 10px;

	opacity: 1;

}

.profile-container .profile-pic-screenname {

	max-width : 1000px;

	position: absolute;

    left: 180px!important;

    margin-top: -18px;

}

.profile-container #profile-pic {

	position: absolute;

    top: 0;

    left: 0;

    width: 168px;

    height: 168px;

    border-radius: 50%;

    overflow: hidden;

    cursor : pointer;

}

button.edit-btn {

	background : #ffffff url(../../images/edit-icon.png) no-repeat center center;

	border : 0 none;

	background-size: 18px 18px;

	position: absolute;

	top : 30px;

	right : 20px;

	z-index : 100;

	cursor: pointer;
}

button.edit-btn a{

	display: inline-block;

	text-indent: -9999px;

	width: 18px;

	height : 18px;

}

button.add-btn {

	background : #222222;

	border-radius : 10px;

	border:1px solid #222222;

	color : #ffffff;

	width : 60px;

	height : 30px;

	cursor: pointer;

	font-size : 24px;

	height : 40px;

}


button.add-btn.small-font {

	font-size : 14px;

}

button.add-btn a {

	color : #ffffff;

}

.del-row {

	background : transparent url(../../images/clear-icon.png) no-repeat center bottom;

	border : 0 none;

	background-size: 13px 13px;

	z-index : 100;

	cursor: pointer;

	text-indent : -9999px;

	display: inline-block;

}

textarea#profile_summary, textarea#description, textarea#certificationDetails {

	height : 120px;

	max-height : 120px;

	overflow: auto;

	padding : 20px;

	max-width: 100%;

	border : 0 none!important;

}
.profile-summary-textarea {
	width: 920px;
    border-radius: 20px;
    overflow: hidden;
    height: 120px;
    border: 1px solid rgba(0,0,0,0.44);
}

table tr td {

	vertical-align: text-top;
	
}

.auth nav .nav-wrapper.white .brand-logo {

	padding : 10px 0;
}

.auth nav .nav-wrapper.white .brand-logo img {

	width: 240px;

	max-width : 100%;

}
table.striped select {

	border-radius : 20px;

	border:1px solid rgba(0,0,0,.44);

	outline : none;

	width : 180px;

}

.skills-table {

	max-height: 400px;

	overflow: auto;
}

.extension-error {
	position: absolute;
	top: 20px;
    left: 20px;
    text-align: center;
}
.profile-container .edit-btn {
	top: auto;
    bottom: 20px;
    right: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ffffff url(../../images/file-upload-camera.png) no-repeat center center;
    background-size: 18px 16px;

}
.profile-container .del-btn {
	top: 20px;
    bottom: auto;
    right: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ffffff url(../../images/trash-icon.png) no-repeat center center;
    background-size: 18px 16px;
    z-index: 102;
    position: absolute;
    cursor: pointer;
    border: 0 none;

}

.verified-phone svg{
	color : green;
}
.notverified-phone svg{
	color : red;
}

.bordered {

	border : 1px solid #e4e4e4;
	border-radius:10px;

	width : 30%!important;
	margin: 10px 1.6% 30px!important;

	padding : 10px!important;
	cursor: pointer;
}
.datepicker{

	background : transparent url(../../images/date-icon.png) no-repeat 95% center;
}

.datepicker.start-date, .datepicker.end-date {
	height : 32px!important;
	width: 150px!important;

	border-radius: 0!important;

	background-size: 18px 18px!important;

	border-color : #e4e4e4!important;
}

#minBudget, #maxBudget{

	background : transparent url(../../images/euro-icon.png) no-repeat 5% center;

	background-size: 16px 16px;

	padding-left : 40px;
}

.file-field.input-field .btn {

	overflow: hidden;
}

button.btn-transparent, button.btn-transparent:active, button.btn-transparent:focus {
	cursor : pointer;
	background:transparent;
	border: 0 none;
}
#searchCategory {
	border-radius: 20px;
	background : transparent;
	height : 32px;
}
.right-border {
	border-right:1px solid #e4e4e4;
}
select:focus {
	outline: none!important;
}
div.nowraptr > div {
    text-overflow: ellipsis;
    white-space: nowrap;
	width: 80%;
    overflow: hidden;
    float: left;
}

div.nowraptr > span {
	width : 20%;
	float:left;
	display: inline-block;
	font-style : italic;
	color : #a59292;
	background: transparent url(../../images/time-icon.png) no-repeat left center;
	background-size: 12px 12px;
	padding-left: 20px;
}

.bid-time {
	background: transparent url(../../images/time-icon.png) no-repeat left center;
	background-size: 18px 18px;
	padding-left: 20px;
	margin-left: -20px;

}

.filled-star {
	color : orange;
}

.fa-icon {
	margin-right : 5px;
}
.status-icons > span > label{
	display: block;
}
.status-icons > span {
    position: relative;
    width: 50px;
    text-align: center;
    display: inline-block;
}

select.browser-default.small{
	width : 60px;
	border-radius: 0;
	height: 32px;
}
.full-width-input .react-datepicker-wrapper {
	width : 100%;
}
.profilepic-wrapper {
	border:1px solid #e4e4e4;
	border-radius : 50%;
	text-align: center;
	width : 42px;
	height: 42px;
	overflow: hidden;
}

.profilepic-wrapper.red-border {
	border-color : red;
}

.profilepic-wrapper.orange-border {
	border-color : orange;
}

.profilepic-wrapper:after {
	content:" ";
	display: inline-block;
	width : 16px;
	height : 21px;
	background: transparent;
	position : absolute;
	margin-top : 18px;
	margin-left: -10px;
}

.profilepic-wrapper.green-border {
	border-color : green;
}
.profilepic-wrapper.red-border:after {
	background: transparent url(../../images/red-shield-icon.png) no-repeat left center;
	background-size: 16px 21px;
}
.profilepic-wrapper.green-border:after {
	background: transparent url(../../images/green-shield-icon.png) no-repeat left center;
	background-size: 16px 21px;
}
.profilepic-wrapper.orange-border:after {
	background: transparent url(../../images/orange-shield-icon.png) no-repeat left center;
	background-size: 16px 21px;
}
nav.rdt_Pagination > span:first-child, nav.rdt_Pagination > span:first-child + div{
	display: none;
}

/* dropdown css  */
.dropbtn {
	background-color: #282828 ;
	padding: 10px;
    text-align: center;
    color: #ffffff;
	border: none;
	
  }
  .dropdown {
	position: relative;
	display: inline-block;
  }
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
	z-index: 1;
	top:37px;
  }
  .dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	font-size: 15px;
  }
  .dropdown-content button {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block !important;
	margin: 0 auto !important;
	width: 100%;
  }
  .dropdown-content a:hover {background-color: #f1f1f1}
  .dropdown:hover .dropdown-content {
	display: block;
	opacity: inherit;
  }
  .dropdown:hover .dropbtn {
	background-color: #282828;
  }