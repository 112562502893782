.upload-section .image-preview {
    margin-top: 10px;
    position: absolute;
    top: 78px;
    max-width: 96%;
    height: 340px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
    width : 100%;
    text-align: center;
   
}

.upload-section .drop-zone {
	position : absolute;
	z-index: 101;
	width : 95%;
	height: 415px;
	opacity: 0.5;


}

.uploader-display-section {

	width : 100%;

	height : 240px;

	border-radius: 10px;

	background : #ffffff;

	border: 1px solid #e4e4e4;

	padding : 0 20px 20px 20px;

	text-align : center;

}

.uploader-display-section.mobile-verification-block {

	height : auto;

	min-height : 240px;
}

.uploader-display-section .preview-section {

	background : #f9f9f9;

	border: 1px dashed #e4e4e4;

  	border-radius : 10px;

  	width : 100%;

	height : 160px;

	position: relative;

	overflow: hidden;

}

.uploader-display-section .preview-section span {

	border : 1px solid #e4e4e4;

	padding : 5px;

	border-radius : 50%;

	width : 32px;

	height : 32px;

	margin : 30px auto 10px;

	display : inline-block;
}
.uploader-display-section .preview-section > div {

	height: 100%;

	width : 100%;
}

.uploader-display-section .preview-section > div > img{

	height : 100%;

}

.uploader-display-section .preview-section span img{

	width : 12px;

}

.uploader-display-section .preview-section button.upload {

	position: absolute;

	top : 0;

	right :0;

	bottom : 0;

	left : 0;

	height : 100%;

	width : 100%;

	opacity: 0.01;

	cursor : pointer;
}

.uploader-display-section .preview-section button.view-large {

	position: absolute;

	bottom : 10px;

	right : 10px;

	z-index: 2;

	cursor : pointer;

	background : #ffffff;

	color : #ffffff;

	border : 1px solid #ffffff;

	border-radius : 5px;

	padding : 5px;

	border-radius : 50%;

	width: 32px;

	height : 32px; 
}

.uploader-display-section .preview-section a.view-btn{

	position: absolute;

	bottom : 10px;

	right : 10px;

	z-index: 2;

	cursor : pointer;

	border-radius : 5px;


}

.close-btn {
	width: 43px;
    height: 43px;
    letter-spacing: 1.5px;
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 50%;
    line-height: 10px;
    padding: 0;
    font-size: 32px;
    transform: rotate(45deg);
    box-shadow: none!important;
    background : #ffffff!important;
    color : #282828;
    border: 0 none;
    cursor : pointer;

}

.react-confirm-alert-body > h1 {
	font-size: 24px!important;
}

.input-field.col label{

	transform: translateY(-30px) scale(0.8)!important;

}

.profile-intro-text {
	position: absolute;
    width: 75%;
    right: 0;
    top: -100px;
    letter-spacing: 0.5px;
}
.countries-list, .category-list {

	height : 320px;

	position: absolute;

	z-index : 98;

	background : #ffffff;

	border: 1px solid #e4e4e4;

	border-radius: 20px;

	overflow: hidden;

	overflow-y : auto;
}

.category-list{

	height : auto;

	width : 90%;

	max-height : 300px;

	overflow : auto;
}

.countries-list{
	height : auto;

	max-width : 360px;

	width : 100%;

	max-height : 300px;
}

.countries-list ul, .category-list ul{

	padding : 10px;

	margin: 0;

	height : 100%;
}

.countries-list li, .category-list li{

	padding : 5px;

	cursor : pointer;

}

.countries-list li:hover, .category-list li:hover{

	background : #282828;

	color : #ffffff;
}

#country, #category, #customer, #serviceRequestId {

	background: #ffffff url(../../images/select-arrow.png) no-repeat 96% center;
}

.multiselect-container > div {

	border-color : transparent!important;
}

.multiselect-container > div input{

	border-radius: 0!important;

	border-color : transparent!important;

	border-bottom : 1px solid #e4e4e4!important;
}

.selected-categories li {

	display: inline;

	background : #f9f9f9;

	border-radius : 10px;

	padding : 5px 10px;

	margin-right : 5px;
}

.selected-categories li .del-row{

	background-size: 8px 8px;

    background-position: center center;

}

.selected-categories-container ul {

	float : left;
}

.selected-categories-container input, .selected-categories-container input[type=text]:not(.browser-default):focus:not([readonly]), .selected-categories-conntainer input:not([type]):focus:not([readonly]){

  border-color: transparent!important;

  border-radius: 0!important;

  float : left;

  width: auto!important;

}

.selected-categories-container{
 	border-bottom: 1px solid rgba(0,0,0,0.44)!important;
    margin-bottom: 40px;
    margin-left: 20px!important;
    margin-right: 20px!important;
    width: 95.5%!important;
    box-sizing: border-box!important;
    max-width: 100%!important;
}

.nowraptr {
	width : 620px;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	cursor: pointer;
}

.view-btn.btn-flat {
	display: flex;
}

table tr td {
    vertical-align: inherit!important;
}

span.file-field.input-field {

	display: inline-block;
    width: 100px;
    height: 100px;
    overflow: hidden;
    float: left;
    margin-right: 20px;
    background: #e4e4e4;
    border: 1px solid #E4E4E3;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

}

span.file-field.input-field img{ 

	max-width : 100%;

}

span.file-field.input-field.attachment {

	background : transparent url(../../images/document.png) no-repeat center center;;
}

span.file-field.input-field.attachment a{
	display: inline-block;
    width: 100%;
    height: 100%;
}

.input-field.col label {
    left: 0.75rem;
    line-height: 28px;
    font-weight: 600;
    color: #282828;
}

.context-menu {
	display: none;
	position: absolute;
}

.context-menu ul{
	width : 100%;
	padding: 0;
	margin:0;
}
.context-menu ul li {
	display: block;
}

.view-btn:hover .context-menu {

	display: block;
}
.context-menu {
	background : #ffffff;
	width : auto;
	border:1px solid #e4e4e4;
	text-align: center;
	min-width : 80px;

	border-radius: 5px;
}

.context-menu ul li:hover{
	background:#f5f5f5;
}